import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout/layout";
import {
  enrichStoryWithGatsbyImages,
  getBlok,
} from "../components/utils/storyblok";
import Seo from "../components/layout/seo";
import {
  getPageSlug,
  prefixUrl,
  suffixTitle,
} from "../components/utils/no-import-commons";

// Statically generate index because Gatsby need it
// somewhat duplicate of templates/page.js
const Index = ({ data }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  enrichStoryWithGatsbyImages(story, data?.images?.nodes ?? []);

  return (
    <Layout languageMappings={{ default: "/", it: "/" }}>
      {(story?.content?.content ?? []).map(getBlok)}
    </Layout>
  );
};

export const Head = ({ data }) => {
  const story = data.storyblokEntry;
  if ("content" in story && typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  const seoInfo = story?.content?.page_information[0];
  seoInfo.url = prefixUrl(getPageSlug(seoInfo.slug, story.lang));
  seoInfo.title = suffixTitle(seoInfo?.title ?? "");

  const extraLangs = ["it"];
  const lm = extraLangs.reduce(
    (obj, l) => {
      obj[l] = prefixUrl(`/${l}`);
      return obj;
    },
    { default: prefixUrl("/") }
  );

  return <Seo info={seoInfo} lm={lm} />;
};

// TODO: come gestire immagini dinamiche in index e id

export const query = graphql`
  query Index {
    storyblokEntry(id: { eq: "storyblokentry-200463909-default" }) {
      field_component
      content
      lang
      name
      slug
    }
    images: allFile(
      filter: {
        base: {
          in: [
            "ref-1_1.jpg"
            "ref-2.png"
            "iddu.jpg"
            "ref-3.jpg"
            "anne-con-scuole-8-of-17.jpg"
            "thumb-web-02.png"
            "areang-1.jpg"
            "constellation-1.jpg"
            "antartic-and-southern-elephant-seal-skull.jpg"
            "fish.png"
            "tim.png"
          ]
        }
      }
    ) {
      nodes {
        base
        publicURL
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  }
`;

export default Index;
